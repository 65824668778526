import React, { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Button, CircularProgress, Divider, Skeleton, Stack, TextField, Typography } from '@mui/material';
import FileUpload, { FileTypes } from '../../Inputs/FileUpload';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import { STATES } from '../../../lib/constants';
import NumberInput from '../../Inputs/NumberInput';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useApplicationContext } from '../../../ApplicationContext';
import Autocomplete from '@mui/material/Autocomplete';
import { useConfirm } from 'material-ui-confirm';
import { enqueueSnackbar } from 'notistack';

const CommunityInformation = ({ community, isLoading }) => {
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();
    const confirm = useConfirm();
    const { selectedCompanyId } = useApplicationContext();
    const [communityData, setCommunityData] = useState(community);
    const [image, setImage] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    useEffect(() => {
        setCommunityData(community);
    }, [community]);

    const onFieldChange = (key, value) => {
        setIsDirty(true);
        setCommunityData((prev) => ({ ...prev, [key]: value }));
    };

    const saveChanges = useCallback(async () => {
        setIsSaving(true);

        let url: string;
        const token = await getAccessTokenSilently();
        const data = { ...communityData };

        if (image) {
            const formData = new FormData();
            formData.append('image', image);

            url = `${process.env.REACT_APP_SENIORLYTICS_API}/api/Company/${selectedCompanyId}/Communities/${community.id}/UploadImage`;
            await axios
                .post(url, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    data.imageURL = response.data;
                })
                .catch(() => {
                    enqueueSnackbar('An error occurred while uploading the image', { variant: 'error' });
                })
                .finally(() => setIsSaving(false));
        }

        url = `${process.env.REACT_APP_SENIORLYTICS_API}/api/Company/${selectedCompanyId}/Communities`;
        await axios
            .post(url, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(() => {
                navigate('/admin/communities');
            })
            .finally(() => setIsSaving(false));
    }, [communityData, getAccessTokenSilently, image, navigate, selectedCompanyId, community.id]);

    const archiveCommunity = useCallback(async () => {
        await confirm({
            title: 'Archive Community',
            description: 'Are you sure you want to archive this community?',
            confirmationText: 'Yes',
            cancellationText: 'Cancel',
            confirmationButtonProps: { variant: 'text', color: 'error' },
        })
            .then(async () => {
                setIsArchiving(true);
                const token = await getAccessTokenSilently();
                const url = `${process.env.REACT_APP_SENIORLYTICS_API}/api/Company/${selectedCompanyId}/Communities/${community.id}`;
                return await axios
                    .delete(url, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(() => {
                        navigate('/admin/communities');
                    })
                    .catch(() => {
                        enqueueSnackbar('An error occurred while archiving the community', { variant: 'error' });
                    });
            })
            .catch(() => {})
            .finally(() => setIsArchiving(false));
    }, [community, getAccessTokenSilently, navigate, selectedCompanyId, confirm]);

    const cancelChanges = async () => {
        if (isDirty) {
            await confirm({
                title: 'Unsaved Changes',
                description: 'Are you sure you want to leave this page? Your changes will not be saved.',
                confirmationText: 'Yes',
                cancellationText: 'No',
            })
                .then(() => {
                    navigate('/admin/communities');
                })
                .catch(() => {});
        } else {
            navigate('/admin/communities');
        }
    };

    return (
        <Grid
            container
            direction={'column'}
            sx={{
                mt: 2,
                background: '#ffffff',
            }}
        >
            {isLoading || !community ? (
                <Stack spacing={2}>
                    <Stack direction="row" spacing={2}>
                        <Skeleton variant="circular" width={75} height={75} />
                        <Stack spacing={2} sx={{ flex: 1 }}>
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                        </Stack>
                    </Stack>
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                    <Skeleton variant="rounded" height={60} />
                </Stack>
            ) : (
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
                            <Button variant={'text'} onClick={archiveCommunity} color={'error'}>
                                {isArchiving && <CircularProgress color={'inherit'} size={20} sx={{ mr: 1 }} />}
                                Archive
                            </Button>
                            <Button variant={'outlined'} onClick={cancelChanges}>
                                Cancel
                            </Button>
                            <Button variant={'contained'} color={'primary'} onClick={saveChanges}>
                                {isSaving && <CircularProgress color={'inherit'} size={20} sx={{ mr: 1 }} />}
                                Save
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            border: '1px solid #dee2e6',
                            borderRadius: 2,
                            padding: '1rem',
                            m: 1,
                        }}
                    >
                        <Grid xs={12} md={4}>
                            <FileUpload
                                required
                                defaultURL={community.imageURL}
                                fileTypes={[FileTypes.PNG, FileTypes.JPEG]}
                                onChange={(i) => {
                                    setImage(i);
                                    setIsDirty(true);
                                }}
                                DropzoneIcon={ApartmentOutlinedIcon}
                                sx={{ height: 160 }}
                            />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Stack spacing={2}>
                                <TextField
                                    required
                                    size={'small'}
                                    label={'Community Name'}
                                    onChange={(e) => onFieldChange('name', e.target.value)}
                                    defaultValue={community.name}
                                />
                                <TextField
                                    disabled
                                    size={'small'}
                                    label={'Code'}
                                    onChange={(e) => onFieldChange('code', e.target.value)}
                                    defaultValue={community.code}
                                />
                                <NumberInput
                                    required
                                    size={'small'}
                                    label={'Latitude'}
                                    onChange={(v) => onFieldChange('latitude', v)}
                                    defaultValue={community.latitude}
                                />
                                <NumberInput
                                    required
                                    size={'small'}
                                    label={'Longitude'}
                                    onChange={(v) => onFieldChange('longitude', v)}
                                    defaultValue={community.longitude}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <Stack spacing={2}>
                                <TextField
                                    required
                                    size={'small'}
                                    label={'Address'}
                                    onChange={(e) => onFieldChange('address', e.target.value)}
                                    defaultValue={community.address}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                />
                                <TextField
                                    required
                                    size={'small'}
                                    label={'City'}
                                    onChange={(e) => onFieldChange('city', e.target.value)}
                                    defaultValue={community.city}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                />
                                <Autocomplete
                                    size={'small'}
                                    autoComplete
                                    id={'company-state-dropdown'}
                                    options={[{ label: 'None', value: '' }, ...STATES]}
                                    isOptionEqualToValue={(option, value) => option.value === value.value}
                                    onChange={(_, state) => onFieldChange('state', state?.value)}
                                    defaultValue={STATES.find((s) => s.value === community.state)}
                                    renderInput={(params) => {
                                        return (
                                            <TextField
                                                {...params}
                                                required
                                                label={'State'}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password',
                                                }}
                                            />
                                        );
                                    }}
                                />
                                <NumberInput
                                    required
                                    size={'small'}
                                    label={'Zip Code'}
                                    onChange={(v) => onFieldChange('zip', v)}
                                    defaultValue={community.zip}
                                    inputProps={{
                                        autoComplete: 'new-password',
                                    }}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} md={8} mdOffset={4}>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'Website'}
                                onChange={(e) => onFieldChange('website', e.target.value)}
                                defaultValue={community.website}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant={'h6'} color={'grey.700'}>
                                Property
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Stack spacing={2}>
                                <DatePicker
                                    sx={{ width: '100%' }}
                                    label={'Date Opened'}
                                    views={['year', 'month', 'day']}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(date) => onFieldChange('openingDate', dayjs(date).format('YYYY-MM-DD'))}
                                    defaultValue={community.openingDate ? dayjs(community.openingDate) : null}
                                />
                                <DatePicker
                                    sx={{ width: '100%' }}
                                    label={'Date Acquired'}
                                    views={['year', 'month', 'day']}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(date) => onFieldChange('acquiredDate', dayjs(date).format('YYYY-MM-DD'))}
                                    defaultValue={community.acquiredDate ? dayjs(community.acquiredDate) : null}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Stack spacing={2}>
                                <DatePicker
                                    sx={{ width: '100%' }}
                                    label={'Date Renovated'}
                                    views={['year', 'month', 'day']}
                                    slotProps={{ textField: { size: 'small' } }}
                                    onChange={(date) => onFieldChange('renovatedDate', dayjs(date).format('YYYY-MM-DD'))}
                                    defaultValue={community.renovatedDate ? dayjs(community.renovatedDate) : null}
                                />
                                <NumberInput
                                    fullWidth
                                    size={'small'}
                                    label={'Site Size (Acreage)'}
                                    onChange={(v) => onFieldChange('siteSize', v)}
                                    defaultValue={community.siteSize}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Stack spacing={2}>
                                <NumberInput
                                    fullWidth
                                    size={'small'}
                                    label={'Total SqFt'}
                                    onChange={(v) => onFieldChange('sqft', v)}
                                    defaultValue={community.sqft}
                                />
                                <NumberInput
                                    fullWidth
                                    size={'small'}
                                    label={'Total Floors'}
                                    onChange={(v) => onFieldChange('floors', v)}
                                    defaultValue={community.floors}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <Stack spacing={2}>
                                <TextField
                                    fullWidth
                                    size={'small'}
                                    label={'Parking'}
                                    onChange={(e) => onFieldChange('parking', e.target.value)}
                                    defaultValue={community.parking}
                                    placeholder={'Surface/Garage'}
                                />
                            </Stack>
                        </Grid>
                        <Grid xs={12}>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant={'h6'} color={'grey.700'}>
                                Grouping
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'Portfolio'}
                                onChange={(e) => onFieldChange('portfolio', e.target.value)}
                                defaultValue={community.portfolio}
                            />
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'Region'}
                                onChange={(e) => onFieldChange('region', e.target.value)}
                                defaultValue={community.region}
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                            />
                        </Grid>
                        <Grid xs={12}>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant={'h6'} color={'grey.700'}>
                                Owner / Operator
                            </Typography>
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <TextField disabled fullWidth size={'small'} label={'Owner'} defaultValue={community.ownerCompany?.name} />
                        </Grid>
                        <Grid xs={12} sm={6} md={3}>
                            <TextField disabled fullWidth size={'small'} label={'Operator'} defaultValue={community.operatorCompany?.name} />
                        </Grid>
                        <Grid xs={12}>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant={'h6'} color={'grey.700'}>
                                Review Sites
                            </Typography>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'APlaceForMom Review URL'}
                                onChange={(e) => onFieldChange('aPlaceForMomURL', e.target.value)}
                                defaultValue={community.aPlaceForMomURL}
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                            />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <TextField
                                fullWidth
                                size={'small'}
                                label={'Caring Review URL'}
                                onChange={(e) => onFieldChange('caringURL', e.target.value)}
                                defaultValue={community.caringURL}
                                inputProps={{
                                    autoComplete: 'new-password',
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default CommunityInformation;
